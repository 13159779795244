var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "daily-table" },
    [
      _c(
        "div",
        { staticClass: "toobar-left" },
        [
          _c("svg", { staticClass: "iconpark-icon" }, [
            _c("use", { attrs: { href: "#rongqi" } }),
          ]),
          _c(
            "a-date-picker",
            {
              attrs: {
                "value-format": "YYYY-MM-DD",
                "disabled-date": _vm.disabledDate,
                bordered: false,
              },
              on: { change: _vm.dateChange },
              model: {
                value: _vm.time,
                callback: function ($$v) {
                  _vm.time = $$v
                },
                expression: "time",
              },
            },
            [_c("span", { staticClass: "date" }, [_vm._v(_vm._s(_vm.time))])]
          ),
          _c("a-button", { staticClass: "month", on: { click: _vm.onday } }, [
            _vm._v("昨天"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "daily-toolbar" },
        [
          _c("div", { staticClass: "daily-statistic-left" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statistcs.requiredNumber > 0,
                    expression: "statistcs.requiredNumber > 0",
                  },
                ],
                staticClass: "statistic-p",
              },
              [_vm._v("出勤：" + _vm._s(_vm.statistcs.requiredNumber))]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statistcs.actualNumber > 0,
                    expression: "statistcs.actualNumber > 0",
                  },
                ],
                staticClass: "statistic-p",
              },
              [_vm._v("打卡：" + _vm._s(_vm.statistcs.actualNumber))]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statistcs.lackNumber > 0,
                    expression: "statistcs.lackNumber > 0",
                  },
                ],
                staticClass: "statistic-p",
              },
              [
                _vm._v("旷工: "),
                _c("span", [_vm._v(_vm._s(_vm.statistcs.lackNumber))]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statistcs.lateNumber > 0,
                    expression: "statistcs.lateNumber > 0",
                  },
                ],
                staticClass: "statistic-p",
              },
              [
                _vm._v("迟到:"),
                _c("span", [_vm._v(" " + _vm._s(_vm.statistcs.lateNumber))]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statistcs.earlyNumber > 0,
                    expression: "statistcs.earlyNumber > 0",
                  },
                ],
                staticClass: "statistic-p",
              },
              [
                _vm._v("早退:"),
                _c("span", [_vm._v(" " + _vm._s(_vm.statistcs.earlyNumber))]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statistcs.missNumber > 0,
                    expression: "statistcs.missNumber > 0",
                  },
                ],
                staticClass: "statistic-p",
              },
              [
                _vm._v("缺卡："),
                _c("span", [_vm._v(_vm._s(_vm.statistcs.missNumber))]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statistcs.leaveNumber > 0,
                    expression: "statistcs.leaveNumber > 0",
                  },
                ],
                staticClass: "statistic-p",
              },
              [
                _vm._v("请假："),
                _c("span", [_vm._v(_vm._s(_vm.statistcs.leaveNumber))]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statistcs.overtimeNumber > 0,
                    expression: "statistcs.overtimeNumber > 0",
                  },
                ],
                staticClass: "statistic-p",
              },
              [
                _vm._v("加班："),
                _c("span", [_vm._v(_vm._s(_vm.statistcs.overtimeNumber))]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statistcs.outNumber > 0,
                    expression: "statistcs.outNumber > 0",
                  },
                ],
                staticClass: "statistic-p",
              },
              [
                _vm._v("外出："),
                _c("span", [_vm._v(_vm._s(_vm.statistcs.outNumber))]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statistcs.travelNumber > 0,
                    expression: "statistcs.travelNumber > 0",
                  },
                ],
                staticClass: "statistic-p",
              },
              [
                _vm._v("出差："),
                _c("span", [_vm._v(_vm._s(_vm.statistcs.travelNumber))]),
              ]
            ),
          ]),
          _c("appForm", {
            ref: "appForm",
            attrs: {
              "to-list": _vm.formList,
              "search-placeholder": "请搜索姓名/工号",
              "screen-roster-list": _vm.screenRosterList,
            },
            on: {
              getSearchCallback: _vm.getSearchCallback,
              screenRosterParams: _vm.screenRosterParams,
              exportFileList: _vm.exportFileList,
              refreshTableList: _vm.refreshTableList,
            },
          }),
        ],
        1
      ),
      _c("custom-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          "row-key": "onJobId",
          pagination: {
            current: _vm.pager.pageNum,
            pageSize: _vm.pager.pageSize,
            total: _vm.total,
            showTotal: function (total) {
              return "共 " + total + " 条数据"
            },
            showQuickJumper: true,
            showSizeChanger: true,
          },
        },
        on: { change: _vm.haedChang },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var scope = ref.scope
              return [_c("span", [_vm._v(_vm._s(scope.index + 1))])]
            },
          },
          {
            key: "staffName",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("CbTableAvater", {
                  attrs: {
                    "table-name": scope.record.staffName,
                    "on-job-id": scope.record.onJobId,
                    "staff-id": scope.record.staffId,
                    title: scope.record.staffName,
                  },
                }),
              ]
            },
          },
          {
            key: "operation",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("div", { staticClass: "flex-align-center" }, [
                  _c(
                    "div",
                    { staticClass: "more-handle" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "link" },
                          on: {
                            click: function ($event) {
                              return _vm.detail(scope.record)
                            },
                          },
                        },
                        [_vm._v(" 详情 ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c(
        "Drawer",
        {
          attrs: {
            visible: _vm.drawer,
            title: _vm.title,
            placement: "right",
            size: "small",
            "wrap-class-name": "detailDrawer",
            "is-mask": "",
            "display-btn": "",
          },
          on: { close: _vm.onClose },
        },
        [
          _c("div", { staticClass: "head" }, [
            _c("div", { staticClass: "content" }, [
              _c("span", { staticClass: "shiftName" }, [
                _vm._v("班次: "),
                _c(
                  "span",
                  {
                    style:
                      "color:rgba(" +
                      (_vm.details.list ? _vm.details.list[0].fontRgba : "") +
                      ")",
                  },
                  [_vm._v(_vm._s(_vm.details.shiftName))]
                ),
              ]),
              _c(
                "span",
                {
                  style:
                    "color:rgba(" +
                    (_vm.details.list ? _vm.details.list[0].fontRgba : "") +
                    ")",
                },
                [_vm._v(_vm._s(_vm.details.dayClockSection) + " ")]
              ),
            ]),
          ]),
          _c("div", { staticClass: "dailytime" }, [
            _c(
              "div",
              { staticClass: "dailytime-head" },
              [
                _c("p", { staticClass: "head-p" }, [
                  _vm._v("合计出勤"),
                  _c("span", [_vm._v(_vm._s(_vm.details.sumHour))]),
                  _vm._v("小时"),
                ]),
                _vm._l(_vm.details.list, function (item) {
                  return _c(
                    "a-timeline",
                    { key: item.staffShiftId },
                    [
                      _c(
                        "a-timeline-item",
                        { attrs: { color: "@sc-grey-60" } },
                        [
                          _c("a-icon", {
                            staticStyle: { "font-size": "16px" },
                            attrs: { slot: "dot", type: "clock-circle-o" },
                            slot: "dot",
                          }),
                          _c("p", { staticStyle: { color: "@sc-gray-100" } }, [
                            _vm._v("上班 "),
                            _c("span", [
                              _vm._v(_vm._s(item.onClockTime || "-")),
                            ]),
                          ]),
                          _c("p", [
                            _vm._v("打卡方式 "),
                            _c("span", [
                              _vm._v(_vm._s(item.onClockMode || "-")),
                            ]),
                          ]),
                          _c("p", [
                            _vm._v("打卡地点 "),
                            _c("span", [
                              _vm._v(_vm._s(item.onClockPlace || "-")),
                            ]),
                          ]),
                          _c("p", [
                            _vm._v("备注 "),
                            _c("span", [
                              _vm._v(_vm._s(item.onClockRemark || "-")),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "a-timeline-item",
                        { attrs: { color: "@sc-grey-60" } },
                        [
                          _c("a-icon", {
                            staticStyle: { "font-size": "16px" },
                            attrs: { slot: "dot", type: "clock-circle-o" },
                            slot: "dot",
                          }),
                          _c("p", { staticStyle: { color: "@sc-gray-100" } }, [
                            _vm._v("下班 "),
                            _c("span", [
                              _vm._v(_vm._s(item.upClockTime || "-")),
                            ]),
                          ]),
                          _c("p", [
                            _vm._v("打卡方式 "),
                            _c("span", [
                              _vm._v(_vm._s(item.upClockMode || "-")),
                            ]),
                          ]),
                          _c("p", [
                            _vm._v("打卡地点 "),
                            _c("span", [
                              _vm._v(_vm._s(item.upClockPlace || "-")),
                            ]),
                          ]),
                          _c("p", [
                            _vm._v("备注 "),
                            _c("span", [
                              _vm._v(_vm._s(item.upClockRemark || "-")),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }