var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "daily-table" },
    [
      !_vm.dragValue
        ? _c(
            "div",
            [
              !_vm.detailVisible
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "daily-toolbar" }, [
                        _c(
                          "div",
                          { staticClass: "left-box" },
                          [
                            _c("svg", { staticClass: "iconpark-icon" }, [
                              _c("use", { attrs: { href: "#rongqi" } }),
                            ]),
                            _c(
                              "a-month-picker",
                              {
                                attrs: {
                                  "value-format": "YYYY-MM",
                                  "disabled-date": _vm.disabledDate,
                                  bordered: false,
                                },
                                on: { change: _vm.dateChange },
                                model: {
                                  value: _vm.time,
                                  callback: function ($$v) {
                                    _vm.time = $$v
                                  },
                                  expression: "time",
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(_vm.time))])]
                            ),
                            _c(
                              "a-button",
                              {
                                staticClass: "month",
                                on: { click: _vm.onday },
                              },
                              [_vm._v("当月")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "toolbar-flex" }, [
                        _c(
                          "div",
                          { staticClass: "toobar-left" },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  prefix: "faqishenpi",
                                  disabled: _vm.ifSend !== "0" || !_vm.ifSend,
                                },
                                on: { click: _vm.allSend },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.ifSend === "2"
                                      ? "已归档"
                                      : _vm.ifSend === "1"
                                      ? "考勤确认中"
                                      : "发送考勤确认"
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "toobar-right" },
                          [
                            _c("appForm", {
                              ref: "appForm",
                              attrs: {
                                "to-list": _vm.formList,
                                "not-shown-list": _vm.notShownList,
                                "display-list": _vm.displayList,
                                "do-not-drag-list": _vm.doNotDragList,
                                "search-placeholder": "请搜索姓名/工号",
                                "screen-roster-list": _vm.screenRosterList,
                              },
                              on: {
                                exportFileList: _vm.exportFileList,
                                getSearchCallback: _vm.getSearchCallback,
                                screenRosterParams: _vm.screenRosterParams,
                                searchHeaderCallback: _vm.searchHeaderCallback,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "daily-statistic-left" }, [
                        _vm.statistcs.requiredNumber > 0
                          ? _c("div", { staticClass: "statistic-p" }, [
                              _vm._v(
                                "出勤：" + _vm._s(_vm.statistcs.requiredNumber)
                              ),
                            ])
                          : _vm._e(),
                        _vm.statistcs.allNumber > 0
                          ? _c("div", { staticClass: "statistic-p" }, [
                              _vm._v(
                                "满勤：" + _vm._s(_vm.statistcs.allNumber)
                              ),
                            ])
                          : _vm._e(),
                        _vm.statistcs.lackNumber > 0
                          ? _c("div", { staticClass: "statistic-p" }, [
                              _vm._v("旷工："),
                              _c("span", [
                                _vm._v(_vm._s(_vm.statistcs.lackNumber)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.statistcs.overtimeNumber > 0
                          ? _c("div", { staticClass: "statistic-p" }, [
                              _vm._v("加班："),
                              _c("span", [
                                _vm._v(_vm._s(_vm.statistcs.overtimeNumber)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.statistcs.missNumber > 0
                          ? _c("div", { staticClass: "statistic-p" }, [
                              _vm._v("缺卡："),
                              _c("span", [
                                _vm._v(_vm._s(_vm.statistcs.missNumber)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.statistcs.lateNumber > 0
                          ? _c("div", { staticClass: "statistic-p" }, [
                              _vm._v("迟到："),
                              _c("span", [
                                _vm._v(_vm._s(_vm.statistcs.lateNumber)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.statistcs.leaveNumber > 0
                          ? _c("div", { staticClass: "statistic-p" }, [
                              _vm._v("请假："),
                              _c("span", [
                                _vm._v(_vm._s(_vm.statistcs.leaveNumber)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.statistcs.earlyNumber > 0
                          ? _c("div", { staticClass: "statistic-p" }, [
                              _vm._v("早退："),
                              _c("span", [
                                _vm._v(_vm._s(_vm.statistcs.earlyNumber)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.statistcs.outNumber > 0
                          ? _c("div", { staticClass: "statistic-p" }, [
                              _vm._v("外出："),
                              _c("span", [
                                _vm._v(_vm._s(_vm.statistcs.outNumber)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.statistcs.travelNumber > 0
                          ? _c("div", { staticClass: "statistic-p" }, [
                              _vm._v("出差："),
                              _c("span", [
                                _vm._v(_vm._s(_vm.statistcs.travelNumber)),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _c("custom-table", {
                        attrs: {
                          columns: _vm.columns,
                          "data-source": _vm.dataSource,
                          "row-key": "attendanceResultId",
                          pagination: {
                            current: _vm.pager.pageNum,
                            pageSize: _vm.pager.pageSize,
                            total: _vm.total,
                            showTotal: function (total) {
                              return "共 " + total + " 条数据"
                            },
                            showQuickJumper: true,
                            showSizeChanger: true,
                          },
                        },
                        on: { change: _vm.haedChang },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function (ref) {
                                var scope = ref.scope
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.index + 1))]),
                                ]
                              },
                            },
                            {
                              key: "attendanceGroupId",
                              fn: function (ref) {
                                var scope = ref.scope
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "attendanceGroupId",
                                      attrs: {
                                        title: scope.record.attendanceGroupName,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(scope.record.attendanceGroupName)
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "staffName",
                              fn: function (ref) {
                                var scope = ref.scope
                                return [
                                  _c("CbTableAvater", {
                                    staticClass: "staffName",
                                    attrs: {
                                      "table-name": scope.record.staffName,
                                      "on-job-id": scope.record.onJobId,
                                      "staff-id": scope.record.staffId,
                                      title: scope.record.staffName,
                                    },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "confirmStatus",
                              fn: function (ref) {
                                var scope = ref.scope
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getCodeContent(
                                          scope.record.confirmStatus,
                                          _vm.attendance_confirm_status
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "operation",
                              fn: function (ref) {
                                var scope = ref.scope
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "flex-align-center" },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          staticClass: "detail",
                                          attrs: { type: "link" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.detail(scope.record)
                                            },
                                          },
                                        },
                                        [_vm._v(" 详情 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4097820703
                        ),
                      }),
                    ],
                    1
                  )
                : _c("SummaryDetail", {
                    attrs: {
                      "on-job-id": _vm.onJobId,
                      "user-name": _vm.userName,
                      date: _vm.time,
                    },
                    on: { goBack: _vm.goBack },
                  }),
            ],
            1
          )
        : _c("ExcelImport", {
            attrs: { title: _vm.importTitle },
            on: {
              toggleValue: function ($event) {
                _vm.dragValue = false
              },
            },
          }),
      _c(
        "Modal",
        {
          attrs: {
            closable: true,
            size: "normal",
            title: "发送本月考勤确认",
            visible: _vm.visible,
            "ok-text": "发送",
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _c("div", { staticClass: "modal-main" }, [
            _c("p", { staticStyle: { color: "#4c5466" } }, [
              _vm._v("规则说明"),
            ]),
            _c(
              "ul",
              { staticStyle: { "margin-bottom": "30px", color: "#202A40" } },
              [
                _c("li", { staticStyle: { "line-height": "30px" } }, [
                  _vm._v("1、每月仅可给每个员工发送一次确认考勤结果通知。"),
                ]),
                _c("li", { staticStyle: { "line-height": "30px" } }, [
                  _vm._v(
                    "2、员工收到通知后，若未确认，系统会在第二天11:00，自动再次发送提醒。"
                  ),
                ]),
                _c("li", { staticStyle: { "line-height": "30px" } }, [
                  _vm._v(
                    "3、若员工在收到两次提醒后仍未确认，系统将依据您设置的自动确认时间为员工自动确认。"
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "aredio", staticStyle: { color: "#4c5466" } },
              [
                _vm._v(" 自动确认时间 "),
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "150px", "margin-left": "20px" },
                    model: {
                      value: _vm.confirmTime,
                      callback: function ($$v) {
                        _vm.confirmTime = $$v
                      },
                      expression: "confirmTime",
                    },
                  },
                  [
                    _c("a-select-option", { attrs: { value: 3 } }, [
                      _vm._v(" 收到提醒3天后 "),
                    ]),
                    _c("a-select-option", { attrs: { value: 2 } }, [
                      _vm._v(" 收到提醒2天后 "),
                    ]),
                    _c("a-select-option", { attrs: { value: 1 } }, [
                      _vm._v(" 收到提醒1天后 "),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }