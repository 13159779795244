var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "daily-table" },
    [
      _c(
        "div",
        { staticClass: "toobar-left" },
        [
          _c("svg", { staticClass: "iconpark-icon" }, [
            _c("use", { attrs: { href: "#rongqi" } }),
          ]),
          _c(
            "a-month-picker",
            {
              attrs: {
                "value-format": "YYYY-MM",
                "disabled-date": _vm.disabledDate,
              },
              on: { change: _vm.dateChange },
              model: {
                value: _vm.time,
                callback: function ($$v) {
                  _vm.time = $$v
                },
                expression: "time",
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.time))])]
          ),
          _c("a-button", { staticClass: "month", on: { click: _vm.onMonth } }, [
            _vm._v("当月"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "daily-toolbar" },
        [
          _c("div", { staticClass: "daily-statistic-left" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statistcs.lackNumber > 0,
                    expression: "statistcs.lackNumber > 0",
                  },
                ],
                staticClass: "statistic-p",
              },
              [
                _vm._v("旷工："),
                _c("span", [_vm._v(_vm._s(_vm.statistcs.lackNumber))]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statistcs.lateNumber > 0,
                    expression: "statistcs.lateNumber > 0",
                  },
                ],
                staticClass: "statistic-p",
              },
              [
                _vm._v("迟到："),
                _c("span", [_vm._v(_vm._s(_vm.statistcs.lateNumber))]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statistcs.earlyNumber > 0,
                    expression: "statistcs.earlyNumber > 0",
                  },
                ],
                staticClass: "statistic-p",
              },
              [
                _vm._v("早退："),
                _c("span", [_vm._v(_vm._s(_vm.statistcs.earlyNumber))]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statistcs.missNumber > 0,
                    expression: "statistcs.missNumber > 0",
                  },
                ],
                staticClass: "statistic-p",
              },
              [
                _vm._v("缺卡："),
                _c("span", [_vm._v(_vm._s(_vm.statistcs.missNumber))]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statistcs.outNumber > 0,
                    expression: "statistcs.outNumber > 0",
                  },
                ],
                staticClass: "statistic-p",
              },
              [
                _vm._v("外勤打卡："),
                _c("span", [_vm._v(_vm._s(_vm.statistcs.outNumber))]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statistcs.overtimeNumber > 0,
                    expression: "statistcs.overtimeNumber > 0",
                  },
                ],
                staticClass: "statistic-p",
              },
              [
                _vm._v("加班："),
                _c("span", [_vm._v(_vm._s(_vm.statistcs.overtimeNumber))]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statistcs.leaveNumber > 0,
                    expression: "statistcs.leaveNumber > 0",
                  },
                ],
                staticClass: "statistic-p",
              },
              [
                _vm._v("请假："),
                _c("span", [_vm._v(_vm._s(_vm.statistcs.leaveNumber))]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.statistcs.travelNumber > 0,
                    expression: "statistcs.travelNumber > 0",
                  },
                ],
                staticClass: "statistic-p",
              },
              [
                _vm._v("出差："),
                _c("span", [_vm._v(_vm._s(_vm.statistcs.travelNumber))]),
              ]
            ),
          ]),
          _c("appForm", {
            ref: "appForm",
            attrs: {
              "to-list": _vm.formList,
              "search-placeholder": "请搜索姓名/工号",
              "screen-roster-list": _vm.screenRosterList,
            },
            on: {
              getSearchCallback: _vm.getSearchCallback,
              screenRosterParams: _vm.screenRosterParams,
              exportFileList: _vm.exportFileList,
            },
          }),
        ],
        1
      ),
      _c("custom-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          "row-key": "attendanceResultId",
          pagination: {
            current: _vm.pager.pageNum,
            pageSize: _vm.pager.pageSize,
            total: _vm.total,
            showTotal: function (total) {
              return "共 " + total + " 条数据"
            },
            showQuickJumper: true,
            showSizeChanger: true,
          },
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var scope = ref.scope
              return [_c("span", [_vm._v(_vm._s(scope.index + 1))])]
            },
          },
          {
            key: "userName",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("CbTableAvater", {
                  attrs: {
                    "table-name": scope.record.staffName,
                    "on-job-id": scope.record.onJobId,
                    "staff-id": scope.record.staffId,
                    title: scope.record.staffName,
                  },
                }),
              ]
            },
          },
          {
            key: "thedetail",
            fn: function (ref) {
              var scope = ref.scope
              return _vm._l(scope.record.abnormals, function (col) {
                return _c("div", { key: col.title }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: col.number > 0,
                          expression: "col.number>0",
                        },
                      ],
                      staticClass: "btn",
                      style: {
                        backgroundColor: col.backgroundColor,
                        color: col.color,
                      },
                    },
                    [_vm._v(_vm._s(col.title) + " " + _vm._s(col.number))]
                  ),
                ])
              })
            },
          },
          {
            key: "operation",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c(
                  "div",
                  { staticClass: "flex-align-center" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          disabled: scope.record.ifFill === "false",
                          type: "link",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.submit(scope.record)
                          },
                        },
                      },
                      [_vm._v(" 补卡 ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("PunchModal", { ref: "punchModal", on: { updata: _vm.updata } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }